import React, { createContext, useState, useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [progress, setProgress] = useState(100);

  const showCustomToast = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setProgress(100);
    startTimer();
  };

  const startTimer = () => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev - 2;
        if (newProgress <= 0) {
          clearInterval(timer);
          setShowToast(false);
        }
        return newProgress;
      });
    }, 100);

    setTimeout(() => {
      clearInterval(timer);
      setShowToast(false);
    }, 5000);
  };

  const getProgressBarColor = () => {
    if (toastType === "success") {
      return "#12623d";
    }
    if (toastType === "alert") {
      return "#d06711";
    }
    if (toastType === "error") {
      return "#c12231";
    }
  };

  return (
    <ToastContext.Provider value={{ showCustomToast }}>
      {children}
      <ToastContainer position="bottom-end" className="p-3 ajust-toast">
        <Toast onClose={() => setShowToast(false)} show={showToast}>
          <Toast.Body
            className={`d-flex ${toastType === "alert" ? "alert" : toastType === "error" ? "erro" : "success"}`}
          >
            <div className="toast-body">{toastMessage}</div>
            <button
              type="button"
              className="btn-close me-2 m-auto"
              onClick={() => setShowToast(false)}
              aria-label="Close"
            ></button>
          </Toast.Body>
          <div
            className="bar-time"
            style={{
              height: "5px",
              width: `${progress}%`,
              backgroundColor: getProgressBarColor(),
              transition: "width 0.1s linear",
            }}
          />
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
};
