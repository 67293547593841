import AppRoutes from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ToastProvider } from "./context/Toast";
import { LoadProvider } from "./context/Load";

function App() {
  return (
    <LoadProvider>
      <ToastProvider>
        <AuthProvider>
          <Router>
            <AppRoutes />
          </Router>
        </AuthProvider>
      </ToastProvider>
    </LoadProvider>
  );
}

export default App;
