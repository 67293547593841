import { React, useState, useEffect, useRef } from "react";
import { useAuth } from "../../../context/Auth";
import { useToast } from "../../../context/Toast";
import { useNavigate } from "react-router-dom";

import "../styles.css";

import { eyeSlash, eye } from "../../../imports/icons";
import { Navigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = useState(false);

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  const { signInWithEmailAndPassword, signed } = useAuth();

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const [email, setEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isFormValid = emailInput !== "" && passwordInput;

  const { showCustomToast } = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (passwordInputRef.current) {
      passwordInputRef.current.type = showPassword ? "password" : "text";
    }
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  async function handleEmailAndPasswordSignIn(e) {
    e.preventDefault();
    setLoading(true);
    try {
      signInWithEmailAndPassword(emailInput, passwordInput);
      setLoading(false);
    } catch (err) {
      showCustomToast("Erro ao autenticar com email e senha", "error");
      console.error("Erro ao autenticar com email e senha", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
  }, [email]);

  useEffect(() => {
    setEmail(true);
  }, []);

  if (!signed) {
    return (
      <section className="ct-access">
        <div className="access-l"></div>
        <div className="access-r">
          <form>
            <p className="label-links">
              Não é Membro?{" "}
              <span className="links" onClick={() => navigate("/register")}>
                Criar conta
              </span>
            </p>
            <h1>
              DE VOLTA À{" "}
              <span className="brand" onClick={() => navigate("/")}>
                <strong>3ÄDY</strong>
              </span>
            </h1>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                ref={emailInputRef}
                value={emailInput}
                onChange={handleEmailChange}
              />
              <label for="floatingInput">Endereço de E-mail</label>
            </div>
            <div class="form-floating">
              <input
                type="password"
                class="form-control password-login"
                id="floatingPassword"
                ref={passwordInputRef}
                value={passwordInput}
                onChange={handlePasswordChange}
              />
              <label for="floatingPassword">Sua senha</label>
              <span className="eye" onClick={togglePasswordVisibility}>
                <img
                  loading="lazy"
                  alt="eye"
                  src={showPassword ? eyeSlash : eye}
                />
              </span>
            </div>
            <p className="label-links label-password">
              Esqueceu sua senha?{" "}
              <span className="links" onClick={() => navigate("/password")}>
                Criar uma nova
              </span>
            </p>
            <div>
              {loading ? (
                <button
                  type="submit"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Aguarde...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleEmailAndPasswordSignIn}
                  disabled={!isFormValid}
                >
                  Acessar minha conta
                </button>
              )}
            </div>
            <p className="label-links support">
              Caso tenha problema com seu acesso, fale conosco atravez do
              WhatsApp: <span className="links">+55 11 955478922.</span>
            </p>
          </form>
        </div>
      </section>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
}

export default Login;
