import {
  firestore,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "../Connection";
import generateClientCode from "./GenerateClientCode";

const createClientInFirestore = async (user) => {
  const clientCode = await generateClientCode();

  const clientAuth = {
    client_code: clientCode,
    client_user: user.email,
    client_img: user.imgId,
    client_name: user.name,
    client_password: user.password,
    client_verification_code: "",
    client_language: {
      client_language_pt: true,
      client_language_en: false,
      client_language_fr: false,
    },
    client_updated_user: null,
    client_updated_password: null,
    client_metadata: {
      client_ip: user.clientIp,
      client_last_location: user.location,
      client_last_login: null,
    },
    client_2fa: {
      client_method: "",
      client_status: false,
    },
  };

  const clientData = {
    access: {
      client_permission: user.permission,
      client_creation_date: user.creationTime,
      client_rule: user.rule,
      client_status: user.status,
      client_code: clientCode,
      client_verified: false,
    },
    address: {
      client_country: "",
      client_state: "",
      client_city: "",
      client_district: "",
      client_street: "",
      client_number: "",
      client_complement: "",
      client_postal_code: "",
    },
    client: {
      client_birth: "",
      client_cnpj: "",
      client_cpf: "",
      client_id: "",
      client_gender: {
        client_gender_others: false,
        client_gender_woman: false,
        client_gender_man: false,
        client_gender_unidentified: false,
      },
      client_name: user.name,
      client_nickname: "",
      client_img: user.imgId,
      client_indication: user.indication,
    },
    documents: {
      client_aml: {
        update_date: null,
        acceptance_date: null,
        aml_verification: false,
      },
      client_kyc: {
        update_date: null,
        acceptance_date: null,
        kyc_verification: false,
      },
      client_privacy: {
        update_date: null,
        acceptance_date: user.creationTime,
        client_terms: true,
      },
      client_service: {
        update_date: null,
        acceptance_date: user.creationTime,
        client_policy: true,
      },
    },
    contact: {
      client_email: user.email,
      client_phone: "",
      client_whatsapp: "",
      client_instagram: "",
      client_facebook: "",
      client_x: "",
    },
    equity: {},
    wallet: {
      amount_yad: "0.00",
    },
    learn: {},
    finance: {},
    calculator: {},
    representative: [],
    education: {},
    producer: {},
    quote: {},
  };

  const clientNotify = {
    client_code: clientCode,
    client_user: user.email,
    client_name: user.name,
    notifications: [],
  };

  await setDoc(doc(firestore, "Auth", user.email), clientAuth);
  await setDoc(doc(firestore, "User", clientCode), clientData);
  await setDoc(doc(firestore, "Notify", clientCode), clientNotify);

  if (user.indication) {
    const indicationRef = doc(firestore, "User", user.indication);
    const indicationDoc = await getDoc(indicationRef);

    if (indicationDoc.exists()) {
      await updateDoc(indicationRef, {
        representative: arrayUnion({
          member_indication: clientCode,
          member_status: user.status,
          client_permission: user.permission,
          member_creation_date: user.creationTime,
          member_amount: {
            monthly_payment: {
              // amount_receive: "",
              // payment_extract: [
              //   {
              //     payment_date: null,
              //     payment_amount: "",
              //   }
              // ],
            },
            annual_payment: {
              // amount_receive: "",
              // split_payment: "",
              // payment_extract: [
              //   {
              //     payment_date: null,
              //     payment_amount: "",
              //   }
              // ],
            },
          },
        }),
      });
    }
  }
};

export default createClientInFirestore;
