// import jwt from "jsonwebtoken";
import bcrypt from "bcryptjs";
import {
  firestore,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
} from "./Connection";

export const getClientLocation = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return `${data.city}, ${data.region}, ${data.country_name}`;
  } catch (error) {
    console.error("Erro ao obter a localização do cliente", error);
    return "Localização desconhecida";
  }
};

export const getUserIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (err) {
    console.error("Erro ao obter o IP do usuário:", err);
    return null;
  }
};

export const getCountryCode = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    let countryCode = data.country_code || "BR";

    if (countryCode.length > 2) {
      countryCode = countryCode.slice(0, 2);
    }

    if (countryCode.length < 2) {
      countryCode = countryCode + "O";
    }

    return countryCode;
  } catch (error) {
    console.error("Erro ao obter o código do país", error);
    return "BR";
  }
};

export const authenticateUser = async (email, password) => {
  try {
    const userDocRef = doc(firestore, "Auth", email);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("101");
    }

    const userData = userDoc.data();
    const isPasswordValid = await bcrypt.compare(
      password,
      userData.client_password
    );

    if (!isPasswordValid) {
      throw new Error("103");
    }

    return userData;
  } catch (error) {
    throw new Error(error.message || "105");
  }
};

export const getVerificationCode = async (email) => {
  let clientCode = "";
  let codeExists = false;

  while (!codeExists) {
    const docRef = doc(firestore, "Auth", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      clientCode = docSnap.data().client_verification_code;
      codeExists = clientCode !== undefined && clientCode !== "";
    } else {
      codeExists = true;
    }
  }

  return clientCode;
};

export const createVerificationCode = async (email) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let clientCode = "";
  let clientName = "";

  for (let i = 0; i < 3; i++) {
    clientCode += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  clientCode += "-";
  for (let i = 0; i < 3; i++) {
    clientCode += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  const docRef = doc(firestore, "Auth", email);
  await updateDoc(docRef, { client_verification_code: clientCode });

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    clientName = docSnap.data().client_name;
  }

  return { clientCode, clientName };
};

export const updateVerificationCode = async (email) => {
  const docRef = doc(firestore, "Auth", email);
  await updateDoc(docRef, { client_verification_code: "" });
};

export const updatePassword = async (email, password, data) => {
  const docRef = doc(firestore, "Auth", email);
  await updateDoc(docRef, {
    client_password: password,
    client_updated_password: data,
  });
};

export const getClientData = async (clientCode) => {
  const docRef = doc(firestore, "User", clientCode);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};


export const getNotifications = async (clientCode) => {
  try {
    const docRef = doc(firestore, "Notify", clientCode);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const notifications = docSnap.data().notifications || [];

      return notifications;
    } else {
      console.log("Nenhuma notificação encontrada para este cliente.");
      return []; 
    }
  } catch (error) {
    console.error("Erro ao buscar notificações:", error);
    return [];
  }
};


export const sendNotification = async (clientCode, name, link, message, title) => {

  console.log('Parâmetros recebidos:', { clientCode, name, link, message, title });

  const docRef = doc(firestore, "Notify", clientCode);
  const docSnap = await getDoc(docRef);

  const generateRandomId = () => Math.floor(100000 + Math.random() * 900000);

  const isIdUnique = (id, existingNotifications) => 
    !existingNotifications.some(notification => notification.notify_id === id);

  const generateUniqueId = (existingNotifications) => {
    let newId = generateRandomId();
    while (!isIdUnique(newId, existingNotifications)) {
      newId = generateRandomId();
    }
    return newId;
  };

  const notifyData = new Date().toISOString();

  if (!docSnap.exists()) {
    const notifyId = generateUniqueId([]);
    const newNotification = {
      notify_id: notifyId,
      notify_data: notifyData,
      notify_active: true,
      notify_name: name,
      notify_link: link,
      notify_content: message,
      notify_headline: title,
      notify_client: clientCode,
    };

    await setDoc(docRef, { notifications: [newNotification] });
  } else {
    const existingNotifications = docSnap.data().notifications || [];
    const notifyId = generateUniqueId(existingNotifications);

    const newNotification = {
      notify_id: notifyId,
      notify_data: notifyData,
      notify_active: true,
      notify_name: name,
      notify_link: link,
      notify_content: message,
      notify_headline: title,
      notify_client: clientCode,
    };

    await setDoc(docRef, {
      notifications: arrayUnion(newNotification)
    }, { merge: true });
  }
};

export const markNotificationAsRead = async (clientCode, notifyId) => {
  try {
    const docRef = doc(firestore, "Notify", clientCode);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log("Documento não encontrado.");
      return;
    }
    const existingNotifications = docSnap.data().notifications || [];
    const notificationIndex = existingNotifications.findIndex(
      (notif) => notif.notify_id === notifyId
    );

    if (notificationIndex === -1) {
      console.log("Notificação não encontrada.");
      return;
    }

    const updatedNotifications = [...existingNotifications];
    updatedNotifications[notificationIndex] = {
      ...updatedNotifications[notificationIndex],
      notify_active: false,
    };

    await updateDoc(docRef, {
      notifications: updatedNotifications
    });

    console.log("Notificação marcada como lida.");
  } catch (error) {
    console.error("Erro ao marcar notificação como lida:", error);
  }
};