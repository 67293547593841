import { React, useState, useEffect, useRef } from "react";
import {
  getClientLocation,
  getUserIP,
} from "../../../services/Calls";
import createClientInFirestore from "../../../services/controller/CreateClientInFirestore";
import { profileIcons } from "../../../imports/profile";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/Toast";

import "../styles.css";

import {
  eyeSlash,
  eye,
  checkCircle,
  arrowRightShort,
} from "../../../imports/icons";

function Register() {
  const bcrypt = require("bcryptjs");
  const saltRounds = 10;

  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const indicationInputRef = useRef(null);

  const [indicationInput, setIndicationInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [imageInput, setImageInput] = useState(null);
  const [serviceInput, setServiceInput] = useState(false);
  const [policyInput, setPolicyInput] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showCustomToast } = useToast();

  const [success, setSuccess] = useState(false);

  const isFormValid =
    nameInput !== "" &&
    emailInput !== "" &&
    passwordInput !== "" &&
    imageInput !== null &&
    serviceInput !== false &&
    policyInput !== false;

  const [indication, setIndication] = useState(false);
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [img, setImg] = useState(false);
  const [terms, setTerms] = useState(false);

  const handleIndicationChange = (event) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
    }
    if (inputValue.length > 5) {
      inputValue = `${inputValue.slice(0, 5)}-${inputValue.slice(5)}`;
    }
    if (inputValue.length > 12) {
      inputValue = `${inputValue.slice(0, 12)}-${inputValue.slice(12, 13)}`;
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 14);
    }

    setIndicationInput(inputValue);
  };

  const handleNameChange = (event) => {
    setNameInput(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (id === "service") {
      setServiceInput(checked);
    } else if (id === "policy") {
      setPolicyInput(checked);
    }

    event.target.blur();
  };

  const handleImageClick = (key) => {
    setImageInput(key);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (passwordInputRef.current) {
      passwordInputRef.current.type = showPassword ? "password" : "text";
    }
  };

  const imageGroups = Object.keys(profileIcons).reduce((acc, key, index) => {
    const groupIndex = Math.floor(index / 4);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push({ key, src: profileIcons[key] });
    return acc;
  }, []);

  const handleStepClick = (step) => {
    setIndication(step === "indication");
    setName(step === "name");
    setEmail(step === "email");
    setPassword(step === "password");
    setImg(step === "img");
    setTerms(step === "terms");

    if (step !== "password") {
      setShowPassword(false);
    }
  };

  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(saltRounds);
      const hashedPassword = await bcrypt.hash(password, salt);
      return hashedPassword;
    } catch (error) {
      console.error("Erro ao gerar hash da senha:", error);
      throw new Error("Falha ao gerar hash da senha");
    }
  };

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (passwordInput.length > 0 && passwordInput.length < 6) {
      showCustomToast("A senha deve ter mais de 6 dígitos", "alert");
      setLoading(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(emailInput)) {
      showCustomToast("Email inválido!", "alert");
      setLoading(false);
      return;
    }

    if (nameInput.length < 3) {
      showCustomToast("O nome deve ter pelo menos 3 caracteres", "alert");
      setLoading(false);
      return;
    } else if (nameInput.split(" ").length < 2) {
      showCustomToast("Por favor, insira seu nome completo", "alert");
      setLoading(false);
      return;
    }

    try {
      const hashedPassword = await hashPassword(passwordInput);
      const creationDate = Timestamp.now();
      const clientLocation = await getClientLocation();
      const clientIp = await getUserIP();

      const user = {
        permission: "free",
        name: nameInput,
        password: hashedPassword,
        imgId: imageInput,
        clientIp: clientIp,
        indication: indicationInput,
        email: emailInput,
        rule: "user",
        status: "inativo",
        creationTime: creationDate,
        updatedTime: null,
        phoneNumber: null,
        location: clientLocation,
      };

      await createClientInFirestore(user);
      setSuccess(true);
      showCustomToast("Conta criada com sucesso!", "success");
    } catch (err) {
      console.error("Erro ao criar cliente no Firestore", err);
      showCustomToast("Erro ao tentar se cadastrar!", "error");
      setLoading(false);
    } finally {
      setLoading(false);
      setNameInput("");
      setEmailInput("");
      setPasswordInput("");
      setImageInput(null);
      setServiceInput(false);
      setPolicyInput(false);
      setIndication(false);
      setName(false);
      setEmail(false);
      setPassword(false);
      setImg(false);
      setTerms(false);
    }
  };

  useEffect(() => {
    if (indication) {
      if (indicationInputRef.current) {
        indicationInputRef.current.focus();
      }
    }
  }, [indication]);

  useEffect(() => {
    if (name) {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
  }, [name]);

  useEffect(() => {
    if (email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
  }, [email]);

  useEffect(() => {
    if (password) {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  }, [password]);

  const captureUrlData = () => {
    const url = window.location.href;
    const match = url.match(/\/register[/?](.+)/);

    if (match) {
      let urlData = match[1];
      urlData = urlData.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

      if (urlData.length > 2) {
        urlData = `${urlData.slice(0, 2)}-${urlData.slice(2)}`;
      }
      if (urlData.length > 5) {
        urlData = `${urlData.slice(0, 5)}-${urlData.slice(5)}`;
      }
      if (urlData.length > 12) {
        urlData = `${urlData.slice(0, 12)}-${urlData.slice(12, 13)}`;
      }
      if (urlData.length > 13) {
        urlData = urlData.slice(0, 14);
      }
      
      setIndicationInput(urlData);
    }
  };

  useEffect(() => {
    setIndication(true);
    captureUrlData();

    document.querySelectorAll(".form-check-input").forEach((checkbox) => {
      checkbox.addEventListener("click", (event) => {
        setTimeout(() => {
          event.target.focus();
        }, 10);
      });
    });
  }, []);

  return (
    <>
      <section className="ct-access">
        <div className="access-l"></div>
        <div className="access-r">
          <form>
            {!success ? (
              <p className="label-links">
                Já é Membro?{" "}
                <span className="links" onClick={() => navigate("/login")}>
                  Acessar conta
                </span>
              </p>
            ) : (
              <p className="label-links">Oopa! Ótima escolha.</p>
            )}
            <h1>
              BEM-VINDO À{" "}
              <span className="brand" onClick={() => navigate("/")}>
                <strong>3ÄDY</strong>
              </span>
            </h1>

            {indication && (
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  ref={indicationInputRef}
                  value={indicationInput}
                  onChange={handleIndicationChange}
                />
                <label for="floatingInput">Alguém indicou você?</label>
              </div>
            )}
            {name && (
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  ref={nameInputRef}
                  value={nameInput}
                  onChange={handleNameChange}
                />
                <label for="floatingInput">Nome Completo</label>
              </div>
            )}
            {email && (
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  ref={emailInputRef}
                  value={emailInput}
                  onChange={handleEmailChange}
                />
                <label for="floatingInput">Endereço de E-mail</label>
              </div>
            )}
            {password && (
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  ref={passwordInputRef}
                  value={passwordInput}
                  onChange={handlePasswordChange}
                />
                <label for="floatingPassword">Sua senha</label>
                <span className="eye" onClick={togglePasswordVisibility}>
                  <img
                    loading="lazy"
                    alt="eye"
                    src={showPassword ? eyeSlash : eye}
                  />
                </span>
              </div>
            )}
            {img && (
              <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-inner">
                  {imageGroups.map((group, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div className="ct-img">
                        {group.map((item) => (
                          <img
                            key={item.key}
                            src={item.src}
                            alt={`Icone ${item.key}`}
                            onClick={() => handleImageClick(item.key)}
                            className={`image-item ${
                              imageInput === item.key ? "selected" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            )}
            {terms && (
              <div class="ct-terms">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="service"
                    checked={serviceInput}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label" for="service">
                    Estou de acordo com os Termos de Serviço
                  </label>
                </div>
                <div class="form-check form-switch second-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="policy"
                    checked={policyInput}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label" for="policy">
                    Concordo com as Política de Privacidade
                  </label>
                </div>
              </div>
            )}
            {success && (
              <>
                <div className="dialog-box-body">
                  <div className="success">
                    <img loading="lazy" alt="success" src={checkCircle} />
                    <p>
                      <strong>Conta criada com sucesso!</strong>
                    </p>
                  </div>
                  <p className="main-content">
                    Cresça em seus próprios termos. Agora você pode fazer parte
                    de um ecossistema criado pra mudar sua vida.
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={() => navigate("/login")}
                  >
                    Acessar minha conta
                  </button>
                </div>
              </>
            )}
            {!success && (
              <>
                <div className="login-indication">
                  <div className={`step-btn ${indication ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("indication")}>01</p>
                  </div>
                  <div>
                    <img loading="lazy" alt="success" src={arrowRightShort} />
                  </div>
                  <div className={`step-btn ${name ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("name")}>02</p>
                  </div>
                  <div>
                    <img loading="lazy" alt="success" src={arrowRightShort} />
                  </div>
                  <div className={`step-btn ${email ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("email")}>03</p>
                  </div>
                  <div>
                    <img loading="lazy" alt="success" src={arrowRightShort} />
                  </div>
                  <div className={`step-btn ${password ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("password")}>04</p>
                  </div>
                  <div>
                    <img loading="lazy" alt="success" src={arrowRightShort} />
                  </div>
                  <div className={`step-btn ${img ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("img")}>05</p>
                  </div>
                  <div>
                    <img loading="lazy" alt="success" src={arrowRightShort} />
                  </div>
                  <div className={`step-btn ${terms ? "active-btn" : ""}`}>
                    <p onClick={() => handleStepClick("terms")}>06</p>
                  </div>
                </div>
                <div>
                  {loading ? (
                    <button
                      type="submit"
                      className="btn btn-outline-dark disable"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Aguarde...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-outline-dark"
                      onClick={handleUserSignUp}
                      disabled={!isFormValid}
                    >
                      Criar minha conta
                    </button>
                  )}
                </div>
                <p className="label-links terms">
                  É importante que você acesse nossos{" "}
                  <span className="links">Termos de Serviço</span> e nossa{" "}
                  <span className="links">Política de Privacidade.</span>
                </p>
              </>
            )}
          </form>
        </div>
      </section>
    </>
  );
}

export default Register;
