import React from "react";
import "./styles.css";

function Navigation() {
  return (
    <div>
      <div></div>
    </div>
  );
}

export default Navigation;
