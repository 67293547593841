import React, { createContext, useState, useContext } from "react";
import Loading from "../components/Loading";

const LoadContext = createContext();

export const useLoading = () => {
  return useContext(LoadContext);
};

export const LoadProvider = ({ children }) => {
  const [showLoad, setShowLoad] = useState(false);

  const showLoading = () => {
    setShowLoad(true);
  };

  const hideLoading = () => {
    setShowLoad(false);
  };

  return (
    <LoadContext.Provider value={{ showLoading, hideLoading }}>
      {children}
      {showLoad && <Loading />}
    </LoadContext.Provider>
  );
};
