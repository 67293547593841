import {
  firestore,
  collection,
  getDocs,
  query,
  where,
} from "../Connection";
import { getCountryCode } from '../Calls';

const generateRandomCode = () => {
  const randomNumber = Math.floor(100000 + Math.random() * 900000);
  const randomDigit = Math.floor(Math.random() * 10);
  return `${randomNumber}-${randomDigit}`;
};

const generateClientCode = async () => {
  let codeExists = true;
  let clientCode = "";

  const currentYear = new Date().getFullYear().toString().slice(-2);
  const countryCode = await getCountryCode();

  while (codeExists) {
    const randomCode = generateRandomCode();
    clientCode = `${currentYear}-${countryCode}-${randomCode}`;

    const q = query(
      collection(firestore, "Auth"),
      where("client_code", "==", clientCode)
    );
    const querySnapshot = await getDocs(q);
    codeExists = !querySnapshot.empty;
  }

  return clientCode;
};

export default generateClientCode;
