import { createContext, useContext, useState, useEffect } from "react";
import { authenticateUser } from "../services/Calls";
import { useToast } from "../context/Toast";
import { Navigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showCustomToast } = useToast();

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    setLoading(false);
  }, []);

  async function signInWithEmailAndPassword(email, password) {
    setLoading(true);

    const errorMessages = {
      101: "Usuário não encontrado!",
      103: "Senha incorreta!",
      105: "Erro ao autenticar, tente novamente!",
      default: "Erro na autenticação, tente mais tarde!",
    };

    try {
      const authenticatedUser = await authenticateUser(
        email,
        password,
        showCustomToast
      );
      setUser(authenticatedUser);
      sessionStorage.setItem("user", JSON.stringify(authenticatedUser));
    } catch (error) {
      const errorMessage =
        errorMessages[error.message] || errorMessages["default"];
      showCustomToast(errorMessage, "error");
      console.error("Erro na autenticação:", error);
    } finally {
      setLoading(false);
    }
  }

  function updateUser(updatedUser) {
    setUser(updatedUser);
    sessionStorage.setItem("user", JSON.stringify(updatedUser));
  }

  function signOut() {
    sessionStorage.clear();
    setUser(null);
    return <Navigate to="/" />;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signInWithEmailAndPassword,
        updateUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
